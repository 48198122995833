<template>
  <v-hover v-slot="{ hover }">
    <v-card class="mx-5 mb-10" :width="$vuetify.breakpoint.smAndDown ? '65vw' : '35vw'" elevation="0"
      color="transparent">

      <v-card-title :class="`headline font-weight-light ${color}--text`" style="word-break: break-word;"
        v-animate-css="getAnimation(index % 2 ? 'fadeInLeft' : 'fadeInRight', 1000 * index, 1000)">
        {{ certTitle }}
      </v-card-title>

      <v-card-subtitle v-animate-css="getAnimation('fadeIn', 1100 * index)">{{ certDate }}</v-card-subtitle>

      <v-expand-transition>
        <div v-show="hover">
          <v-divider :class="color"></v-divider>

          <v-card-text class="primary--text" v-animate-css="getAnimation('fadeIn', 100, 1000)">
            <blockquote :cite="certLink">{{ certText }}</blockquote>
          </v-card-text>
        </div>
      </v-expand-transition>

      <v-card-actions class="text-overline primary--text">
        <v-btn :color="color" :href="certLink" target="_blank" text
          v-animate-css="getAnimation('fadeIn', 1200 * index, 1000)">
          Source
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
import { animateMixin } from "@/mixins/animate"

export default {
  name: 'Cert',
  props: ["index", "certDate", "certTitle", "certText", "certLink", "color"],
  mixins: [animateMixin],
}
</script>

<style scoped>
.v-timeline .v-timeline-item .v-card:not(.v-card--link)::before {
    border-right-color: var(--v-background-base);
}

.v-timeline:before {
    background: var(--v-primary-base);
}
</style>
