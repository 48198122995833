<template>
  <v-card flat color="transparent">
    <v-row>
      <v-col v-for="technology in stack" :key="n" class="d-flex child-flex" cols="auto">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-hover v-slot="{ hover }">
                <v-btn class="v-btn-no-shadow" tile icon :href="technology.href" target="_blank" :disabled="isDisabled(technology)"
                  :max-width="size">
                  <v-avatar :size="hover ? parseInt(size) * 1.25 : size" tile>
                    <img :alt="technology.text" :src="require('@/assets/imgs/technologies/' + technology.image + '.webp')" />
                  </v-avatar>
                </v-btn>
              </v-hover>
            </div>
          </template>
          <span>{{ technology.text }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "Technologies",
  props: ["stack", "size"],
  methods: {
    isDisabled(technology) {
      return technology.href ? false : true
    },
  },
}
</script>
