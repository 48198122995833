<template>
  <v-row class="d-flex align-center justify-center">
    <v-card :width="$vuetify.breakpoint.smAndDown ? '100vw' : '80vw'" elevation="0" color="transparent">
      <v-card-title class="mb-16 highlight-text"
        :style="'font-size: ' + ($vuetify.breakpoint.smAndDown ? '3em' : '5em') + ';'"
        v-animate-css="getAnimation('fadeInUp', 100)">
        Certifications
      </v-card-title>

      <v-card-text>
        <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown">
          <v-timeline-item v-for="(item, i) in items" :key="i">

            <template v-slot:icon>
              <badge :logo="item.icon" :link="item.href" v-animate-css="getAnimation('tada', 1000 * i, 1500)" />
            </template>

            <template v-slot:opposite>
              <div v-animate-css="getAnimation('fadeIn', 1100 * i)">
                <span :class="`headline font-weight-bold ${item.color}--text mx-5`" :color="item.color"
                  v-text="item.date"></span>
              </div>
            </template>

            <cert :cert-title="item.title" :cert-date="item.date" :cert-text="item.text" :cert-link="item.link"
              :color="item.color" :index="i" />

          </v-timeline-item>
        </v-timeline>
      </v-card-text>
      <badges class="ma-16" />
    </v-card>
  </v-row>
</template>

<script>
import Badge from '@/components/Badge';
import Badges from '@/components/Badges';
import Cert from '@/components/Cert';
import { animateMixin } from "@/mixins/animate"

export default {
  name: 'Certs',
  components: {
    Cert,
    Badge,
    Badges,
  },
  mixins: [animateMixin],
  data: () => ({
    items: [
      {
        title: 'AWS Certified Cloud Practitioner',
        date: 'October 13, 2022',
        icon: 'aws-practitioner',
        text: 'Earners of this certification have a fundamental understanding of IT services and their uses in the AWS Cloud. They demonstrated cloud fluency and foundational AWS knowledge. Badge owners are able to identify essential AWS services necessary to set up AWS-focused projects.',
        href: 'https://www.credly.com/badges/070a0019-5c4a-4977-ba32-24ca26db925e/public_url',
        link: 'https://aws.amazon.com/certification/certified-cloud-practitioner/',
        color: 'orange',
      },
      {
        title: 'Microsoft Certified: Azure Fundamentals',
        date: 'October 15, 2022',
        icon: 'azure-fundamentals',
        text: 'Earners of the Azure Fundamentals certification have demonstrated foundational level knowledge of cloud services (workloads, security, privacy, pricing, support) and how those services are provided with Microsoft Azure.',
        href: 'https://learn.microsoft.com/api/credentials/share/en-us/AdrianNie-1859/E06CCDFDE332EB8E?sharingId=50D67EDF51D0B194',
        link: 'https://learn.microsoft.com/en-us/certifications/azure-fundamentals/',
        color: 'blue',
      },
      {
        title: 'JSE – Certified Entry-Level JavaScript Programmer',
        date: 'December 29, 2023',
        icon: 'jse',
        text: 'JSE – Certified Entry-Level JavaScript Programmer certification is a professional credential that demonstrates the candidate’s understanding of the JavaScript language core syntax and semantics, as well as their proficiency in using the most essential elements of the language, tools, and resources to design, develop, and refactor simple JavaScript programs.',
        href: 'https://www.credly.com/badges/39590efb-d127-4242-9cf6-dbf0df53b9b1/public_url',
        link: 'https://js.institute/jse-certification',
        color: 'amber',
      },
      {
        title: 'Microsoft Certified: Azure Administrator Associate',
        date: 'March 3,2024',
        icon: 'azure-administrator',
        text: 'Earning Azure Administrator Associate certification validates the skills and knowledge to implement, manage, and monitor an organization’s Microsoft Azure environment. Candidates have a deep understanding of each implementing, managing, and monitoring identity, governance, storage, compute, and virtual networks in a cloud environment, plus provision, size, monitor, and adjust resources, when needed.',
        href: 'https://learn.microsoft.com/api/credentials/share/en-us/AdrianNie-1859/56C4F63369A97ED3?sharingId=50D67EDF51D0B194',
        link: 'https://learn.microsoft.com/en-us/credentials/certifications/azure-administrator/',
        color: 'blue',
      },
    ],
  }),
}
</script>
