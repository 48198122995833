import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import VueTypedJs from 'vue-typed-js'
import VAnimateCss from 'v-animate-css';

Vue.use(Vuetify);
Vue.use(VueTypedJs);
Vue.use(VAnimateCss);

export default new Vuetify({
    icons: {
        iconfont: 'mdiSvg',
    },
    theme: {
        dark: true,
        themes: {
            dark: {
                primary: '#E0E0E0',
                'accent-darken': '#C44619',
                accent: '#F0490E',
                'accent-lighten': '#FFA000',
                'main-title': '#263238',
                background: '#263238',
            },
            light: {
                primary: '#616161',
                'accent-darken': '#00008B',
                accent: '#9932CC',
                'accent-lighten': '#FF9C9C',
                'main-title': '#FF9C9C',
                background: '#FFFFFF',
            },
        },
        options: {
            customProperties: true,
        },
    },
})
