<template>
  <v-dialog :value="true" @click:outside="$emit('close')" @keydown.esc="$emit('close')" fullscreen>
    <template v-slot:default="dialog">
      <v-card class="background">
        <v-card-title class="highlight-text" style="font-size: 2em;" v-animate-css="getAnimation('fadeInUp', 100, 1500)">
          About Me
        </v-card-title>

        <v-card-subtitle class="my-3 text-overline justify-center">
          <v-row>
          </v-row>
        </v-card-subtitle>

        <v-card-text>
          <p v-for="(part, index) in text" :key="index" style="font-size: 0.8em;"
            v-animate-css="getAnimation('fadeIn', 500, 1500 * index)">
            {{ part }}</p>
        </v-card-text>

        <v-card-actions>
          <v-btn class="ma-16 ml-auto" color="accent" @click="$emit('close')"
            v-animate-css="getAnimation('fadeInDown', 1000, 1500)" outlined>
            Back
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { animateMixin } from "@/mixins/animate"

export default {
  name: "AboutDialog",
  props: ["text",],
  mixins: [animateMixin],
}
</script>
