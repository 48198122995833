<template>
  <v-hover v-slot="{ hover }">
    <v-btn :href="link" target="_blank" icon>
      <v-avatar :size="hover ? badgeSize : badgeSize - 16" tile>
        <v-img :aria-label='logo' :alt="logo + ' icon'" :src="require('@/assets/imgs/badges/' + logo + '.webp')"></v-img>
      </v-avatar>
    </v-btn>
  </v-hover>
</template>

<script>
export default {
  name: 'Badge',
  props: ["logo", "link"],
  computed: {
    badgeSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 64
        case 'sm': return 64
        default: return 112
      }
    },
  },
}
</script>
