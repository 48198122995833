export const animateMixin = {
  methods: {
    getAnimation(name, time, delay=3000, repeat=1) {
      return {
        classes: name,
        delay: time,
        duration: delay,
        iteration: repeat,
      }
    },
  },
}

export default animateMixin
