<template>
  <v-card class="highlight" elevation="0" height="90vh" color="transparent">
    <v-hover v-slot="{ hover }">
      <v-card height="50vh" elevation="0" color="transparent">
        <v-row>
          <v-col v-if="!$vuetify.breakpoint.mobile" cols="auto">
            <v-row class="mx-auto" v-animate-css="getAnimation('fadeIn', 1800)">
              <v-card flat class="mx-16" height="50vh" width="50vh" elevation="0" color="transparent">

                <v-img class="mb-16" max-width="500" alt="Adrian photo" :src="require('@/assets/imgs/photo.webp')"
                  :lazy-src="require('@/assets/imgs/photo_blur.webp')">
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate color="grey lighten-1" aria-label="Loading icon"></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>

              </v-card>
            </v-row>
          </v-col>

          <v-col>
            <div class="ma-16">
              <h1 class="intro-heading" :style="'font-size: ' + textSize + ';'"
                v-animate-css="getAnimation('fadeInLeft', 200)">Hi, my name is <span class="intro-name">Adrian
                  Nieć,</span></h1>

              <p v-if="$vuetify.breakpoint.smAndDown" class="heading mt-5 white--text" :style="'font-size: ' + textSize + ';'"
                v-animate-css="getAnimation('fadeInLeft', 400)">
                <v-btn class="intro-name white--text" @click="loadDialog" outlined>
                  Read more
                </v-btn>
                about me.
              </p>
              <v-componenet :is="dialogComponent" v-bind:text="text" v-if="isDialogOpen"
                @close="isDialogOpen = false"></v-componenet>

              <vue-typed-js :strings="['WEB DEVELOPER', 'DEVOPS', 'LINUX ADMINISTRATOR']" :loop="true" :typeSpeed="90"
                :backSpeed="50" :startDelay="3400" :backDelay="3000" :fadeOut="true" :showCursor="true"
                :cursorChar="'_'">
                <h1 class="intro-heading" :style="'font-size: ' + textSize + ';'">
                  <p v-animate-css="getAnimation('fadeInLeft', 600)">I'm a <span
                      class="typing intro-occupation intro-name"></span>
                  </p>
                </h1>
              </vue-typed-js>
            </div>
          </v-col>
        </v-row>

        <v-expand-transition v-if="!$vuetify.breakpoint.smAndDown">
          <v-card v-if="hover" class="d-flex transition-fast-in-fast-out background v-card--reveal-about white--text"
            style="height: 150%;">

            <v-card-text>
              <v-row class="mt-1 d-flex align-center justify-center">
                <v-col :cols="$vuetify.breakpoint.lgAndUp ? '9' : 'auto'">
                  <p class="ma-5 highlight-text" style="font-size: 2em;"
                    v-animate-css="getAnimation('fadeInUp', 100, 1000)">
                    About me</p>
                  <p v-for="(part, index) in text" :key="index"
                    v-animate-css="getAnimation('fadeIn', 200, 500 + (1000 * index))">
                    {{ part }}</p>
                  <skills v-if="!$vuetify.breakpoint.smAndDown" class="my-16"
                    v-animate-css="getAnimation('flipInX', 1000, 1500)" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-expand-transition>
      </v-card>
    </v-hover>

    <div>
      <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
        <defs>
          <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
        </defs>
        <g class="parallax">
          <use class="wave-color" xlink:href="#gentle-wave" x="48" y="0" />
          <use class="wave-color" xlink:href="#gentle-wave" x="48" y="3" />
          <use class="wave-color" xlink:href="#gentle-wave" x="48" y="5" />
          <use class="wave-color" xlink:href="#gentle-wave" x="48" y="7" />
        </g>
      </svg>
    </div>

    <v-card class="background" height="50vh" elevation="0">
      <v-row align="center" justify="center">
        <v-card-title class="primary--text" style="font-size: 1.3em;" v-animate-css="getAnimation('wobble', 8000, 1500)"
          id="goProjects">Explore projects</v-card-title>
      </v-row>

      <v-row align="center" justify="center">
        <v-card-subtitle>
          <v-btn @click="$vuetify.goTo('#projects')" large fab class="white--text intro-arrow highlight v-btn-no-shadow"
            v-animate-css="getAnimation('tada', 500, 8000, 'infinite')">
            <v-icon aria-label="Go to projects" role="img" aria-hidden="false" size="2em">{{ mdiChevronDown }}</v-icon>
          </v-btn>
        </v-card-subtitle>
      </v-row>
    </v-card>
  </v-card>
</template>

<script>
import Skills from '@/components/Skills';
import AboutDialog from '@/components/AboutDialog';
import { animateMixin } from "@/mixins/animate"
import { mdiChevronDown } from '@mdi/js'

export default {
  name: 'Intro',
  components: {
    Skills,
    AboutDialog,
  },
  mixins: [animateMixin],
  data: () => ({
    text: {
      1: "Since I remember I was fascinated by new techologies rapidly adapting and getting into our daily routines to make life easier. I always wanted to be a part of that change. To help someone with my knowledge and expertise in creation something outstanding. That led me to BSc degree in the Computer Science field of study at one of the most renowned universities in Poland - AGH University of Science and Technology.",
      2: "My very first professional experience began in the charming and breathtaking Norway in the produce packhouse. Hard work and dedication led me through the all entry positions ending up as a leader for a team of fifteen. Within the scope of my duties was to configure and operate crucial industry machines, e.g. Greefa EasySort. Born curiosity and desire to develop crossed my professional path with Rockwell Automation - one of the leading companies in automation sector. I was responsible there for porting various test domains from version 2 to 3 of Python programming language. Kyndryl was the next step in my career. I had opportunity there to enhance my proficiency in Linux administration, broaden my knowledge of cloud solutions, and obtain industry certifications.",
      3: "Currently, I hold the position of Junior Administrator at Hostersi, where my daily responsibilities play a pivotal role in enhancing my skill set. Operating at the first line of support, I consistently address a wide spectrum of intricate issues. This role has afforded me a comprehensive understanding of contemporary technologies, including, but not limited to: Elastic Stack, Kubernetes, and Docker. I've honed my expertise in infrastructure as code management through the utilization of Terraform. Moreover, I have had the the privilege of working with diverse cloud services, with a predominant focus on AWS and Azure. Every day at work presents a fresh set of challenges which fuel my enthusiasm for continual advancement in a professional context.",
    },
    dialogComponent: null,
    isDialogOpen: false,
    mdiChevronDown,
  }),
  methods: {
    loadDialog() {
      this.dialogComponent = "AboutDialog"
      this.isDialogOpen = true
    },
  },
  computed: {
    textSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '1.2em'
        case 'xl': return '3em'
        default: return '2em'
      }
    },
  },
}
</script>
