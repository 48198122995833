<template>
  <v-card color="transparent" elevation="0">
    <v-card v-if="!$vuetify.breakpoint.mobile" class="highlight contact-box" height="60vh" elevation="0"
      v-animate-css="getAnimation('fadeIn', 500)">
      <v-row align="center" justify="center">
        <v-card-title class="contact-text" style="font-size: 4.2em;"
          v-animate-css="getAnimation('zoomInDown', 500, 2000)">CONTACT</v-card-title>
      </v-row>

      <v-row align="center" justify="center">
        <v-card-subtitle class="mt-10 contact-text" style="font-size: 1.5em;"
          v-animate-css="getAnimation('fadeIn', 1300)">Would you like to work with me? Awesome!
        </v-card-subtitle>
      </v-row>

      <v-row align="center" justify="center">
        <div v-animate-css="getAnimation('fadeIn', 1500)">
          <v-hover v-slot="{ hover }">
            <v-btn class="contact-text mt-3" :class="hover ? 'white accent--text' : 'white--text'" href="cv.pdf"
              target="_blank" outlined>
              Download CV
            </v-btn>
          </v-hover>
        </div>
      </v-row>

      <v-row align="center" justify="center">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mt-7 contact-text v-btn-no-shadow white--text" style="font-size: 1.5em;"
              @click="$vuetify.goTo('#intro')" v-bind="attrs" v-on="on" text large fab
              v-animate-css.hover="getAnimation('headShake', 0)">
              <v-icon aria-label="To the top" role="img" aria-hidden="false">{{ mdiChevronUp }}</v-icon>
            </v-btn>
          </template>
          <span>To the top</span>
        </v-tooltip>
      </v-row>
    </v-card>

    <v-card color="transparent" height="20vh" min-height="200px" elevation="0">
      <v-card-text>
        <v-row align="center" justify="center">
          <v-card-title class="mt-16 highlight-text" style="font-size: 3em;"
            v-animate-css="getAnimation('flipInY', 100)">Let's
            talk!</v-card-title>
        </v-row>

        <v-row v-if="$vuetify.breakpoint.mobile" align="center" justify="center">
          <v-btn class="mb-5" href="cv.pdf" target="_blank" outlined>
            Download CV
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
import { animateMixin } from "@/mixins/animate"
import { mdiChevronUp } from '@mdi/js'

export default {
  name: 'Contact',
  mixins: [animateMixin],
  data: () => ({
    mdiChevronUp,
  }),
}
</script>
