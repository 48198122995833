<template>
  <v-row class="d-flex align-center justify-center">
    <v-card width="85vw" elevation="0" color="transparent" v-animate-css="getAnimation('fadeIn', 200)">
      <v-card-text>
        <v-carousel height="200px" interval="10000" cycle hide-delimiters>
          <v-carousel-item :key="i" v-for="i in Math.ceil(items.length / displayed)">

            <v-row class="fill-height" align="center" justify="center">
              <v-col align="center" :key="j" v-for="j in displayed">
                <v-tooltip bottom nudge-bottom="50">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <badge :logo="items[j - 1 + displayed * (i - 1)].icon"
                        :link="items[j - 1 + displayed * (i - 1)].href" />
                    </div>
                  </template>
                  <span>{{ items[j - 1 + displayed * (i - 1)].title }}</span>
                </v-tooltip>
              </v-col>
            </v-row>

          </v-carousel-item>
        </v-carousel>
      </v-card-text>
    </v-card>
  </v-row>
</template>

<script>
import Badge from '@/components/Badge';
import { animateMixin } from "@/mixins/animate"

export default {
  name: 'Badges',
  components: {
    Badge,
  },
  mixins: [animateMixin],
  data: () => ({
    model: 0,
    displayed: 5,
    items: [
      {
        title: 'Agile Explorer',
        icon: 'agile-explorer',
        href: 'https://www.credly.com/badges/c2ebcc2e-d429-4f49-8505-e4e463246728/public_url',
      },
      {
        title: 'Interskill - DevOps Fundamentals',
        icon: 'interskill-devops-fundamentals',
        href: 'https://www.credly.com/badges/24f7c313-8422-4c33-b5ef-fc8cff484743/public_url',
      },
      {
        title: 'Site Reliability Engineer Bootcamp',
        icon: 'site-reliability-engineer-bootcamp',
        href: 'https://www.credly.com/badges/09e959a2-5821-4ac9-a5b6-ec3a41571749/public_url',
      },
      {
        title: 'Advanced Delivery Explorer',
        icon: 'advanced-delivery-explorer',
        href: 'https://www.credly.com/badges/baf7cb7d-8532-43ca-9085-6bd60679757a/public_url',
      },
      {
        title: 'Interskill - Coaching and Mentoring for Technical Specialists',
        icon: 'interskill-coaching-and-mentoring-for-technical-specialists',
        href: 'https://www.credly.com/badges/bbfb5e6c-f366-4161-8ef7-eac32d3b0e0f/public_url',
      },
      {
        title: 'Introduction to Cybersecurity',
        icon: 'introduction-to-cybersecurity',
        href: 'https://www.credly.com/badges/13a49903-72cc-455e-9cdc-0e8aa5cb7c04/public_url',
      },
      {
        title: 'Interskill - Mainframe Specialist - IBM Mainframe Environment - Fundamentals 2.4',
        icon: 'interskill-mainframe-specialist-ibm-mainframe-environment-fundamentals-2-4',
        href: 'https://www.credly.com/badges/20f90a52-7be4-41e5-8446-2306ceb78323/public_url',
      },
      {
        title: 'Interskill - Zowe™ Fundamentals',
        icon: 'interskill-zowe-fundamentals',
        href: 'https://www.credly.com/badges/08d05421-a06d-4d11-a758-69c4ef3e43a6/public_url',
      },
      {
        title: 'Interskill – Mainframe Specialist - z/OS Management Facility - Experienced',
        icon: 'interskill-mainframe-specialist-z-os-management-facility-experienced',
        href: 'https://www.credly.com/badges/f399ea21-1fe3-437a-ae19-0bcf768de16c/public_url',
      },
      {
        title: 'Interskill - Mainframe Specialist - Linux Operations and Programming - Experienced',
        icon: 'interskill-mainframe-specialist-linux-operations-and-programming-experienced',
        href: 'https://www.credly.com/badges/e52aa357-d3d2-46dc-bdea-7bb302fae9a8/public_url',
      },
      {
        title: 'Interskill - z/OS UNIX System Services - Experienced',
        icon: 'interskill-z-os-unix-system-services-experienced',
        href: 'https://www.credly.com/badges/46863d63-310c-44cf-972c-778c9a7dee4f/public_url',
      },
      {
        title: 'Mainframe Modern Specialist - Foundation',
        icon: 'mainframe-modern-specialist-foundation',
        href: 'https://www.credly.com/badges/a4b85c6a-16cb-411a-b135-13cf50b79260/public_url',
      },
      {
        title: 'Interskill - AIX Systems Administrator - Fundamentals',
        icon: 'interskill-aix-systems-administrator-fundamentals',
        href: 'https://www.credly.com/badges/686cdbc0-cfe2-4e1a-a281-ffac790c7f02/public_url',
      },
      {
        title: 'Interskill - Cloud Foundations',
        icon: 'interskill-cloud-foundations',
        href: 'https://www.credly.com/badges/65bf2c3c-17d0-4a80-bd67-298a28e0c5b9/public_url',
      },
      {
        title: 'Cyber Security Essentials',
        icon: 'cyber-security-essentials',
        href: 'https://www.credly.com/badges/75f87420-0be3-4686-a919-ac27da708455/public_url',
      },
    ],
  }),
}
</script>
