<template>
  <v-bottom-navigation app height="90px" fixed class="background elevation-0">
    <v-container>
      <v-row align="center" justify="center">
        <v-hover v-slot="{ hover }" v-for="item in items" :key="item.title">
          <v-btn class="mt-1 v-btn-no-shadow" :class="$vuetify.breakpoint.xs ? '' : 'mx-3'" :href="item.href" target="_blank" x-small v-animate-css.hover="'bounceIn'">
            <span :class="hover ? 'accent--text' : 'primary--text'">{{ item.title }}</span>
              <v-icon class="accent--text">{{ item.icon }}</v-icon>
          </v-btn>
        </v-hover>
      </v-row>

      <v-row>
        <v-divider class="ma-3 accent" style="margin-left: 30% !important; margin-right: 30% !important;"></v-divider>
      </v-row>
      
      <v-row align="center" justify="center" class="highlight-text" style="font-size: 0.8em; font-weight: 900;">© {{ new Date().getFullYear() }}
        Adrian Nieć</v-row>
    </v-container>
  </v-bottom-navigation>
</template>

<script>
import { mdiEmailFastOutline, mdiGithub, mdiLinkedin, mdiAlphaCBox } from '@mdi/js'

export default {
  name: 'BottomNav',
  data: () => ({
    items: [
      {
        title: 'MailMe',
        icon: mdiEmailFastOutline,
        href: 'mailto:adrian@niec.me',
      },
      {
        title: 'GitHub',
        icon: mdiGithub,
        href: 'https://github.com/adniec',
      },
      {
        title: 'LinkedIn',
        icon: mdiLinkedin,
        href: 'https://www.linkedin.com/in/niec/',
      },
      {
        title: 'Credly',
        icon: mdiAlphaCBox,
        href: 'https://www.credly.com/users/adrian-niec',
      },
    ],
  }),
}
</script>
