<template>
  <v-card elevation="0" color="transparent" class="my-16">
    <v-row class="d-flex align-center justify-center" :class="$vuetify.breakpoint.xs ? 'mx-1' : 'mx-15'">
      <v-col v-for="(item, index) in items" :key="index" class="d-flex child-flex" cols="auto">
        <project v-bind="item" v-animate-css="getAnimation('fadeIn', 500 * index, 1500)" />
      </v-col>
    </v-row>
    <v-card height="15vh" color="transparent" elevation="0"></v-card>
  </v-card>
</template>

<script>
import Project from '@/components/Project';
import { animateMixin } from "@/mixins/animate"

export default {
  name: 'Projects',
  components: {
    Project,
  },
  mixins: [animateMixin],
  data: () => ({
    items: [
      {
        title: 'Pazdzior Art',
        text: 'Created using VueJS, this artist portfolio seamlessly integrates with a MongoDB Atlas database and is linked to the artist\'s Zoho Drive, showcasing a professional and dynamic digital presence.',
        image: 'pazdziorart',
        view: 'https://pazdzior.art',
        docs: '',
        github: '',
        stack: [
          { text: 'Zoho', image: 'zoho', href: 'https://www.zoho.com/' },
          { text: 'Cloudflare', image: 'cloudflare', href: 'https://www.cloudflare.com/' },
          { text: 'MongoDB', image: 'mongodb', href: 'https://www.mongodb.com/' },
          { text: 'VueJS', image: 'vuejs', href: 'https://vuejs.org/' },
        ],
      },
      {
        title: 'FastAPI over Northwind DB',
        text: 'Backend microservices built with FastAPI over Northwind database ported to PostgreSQL and deployed by Docker Swarm. Payments via PayPal sandbox. Sample metrics setup with cAdvisor, Prometheus and Grafana.',
        image: 'northwind',
        docs: '',
        github: 'https://github.com/adniec/fastapi-over-northwind',
        stack: [
          { text: 'Prometheus', image: 'prometheus', href: 'https://prometheus.io/' },
          { text: 'Grafana', image: 'grafana', href: 'https://grafana.com/' },
          { text: 'PayPal', image: 'paypal', href: 'https://www.paypal.com/' },
          { text: 'Docker', image: 'docker', href: 'https://www.docker.com/' },
          { text: 'FastAPI', image: 'fastapi', href: 'https://fastapi.tiangolo.com/' },
          { text: 'Python', image: 'python', href: 'https://www.python.org/' },
        ],
      },
      {
        title: 'pdoc3-mdnotes',
        text: 'Extension used to generate notes from markdown files with pdoc3. It allows to build website with ease. Collect your markdown notes in suitable directory hierarchy for your project and voilà! Program will link them with each other. Check docs for detailed example.',
        image: 'mdnotes',
        docs: 'https://adniec.github.io/pdoc3-mdnotes/',
        github: 'https://github.com/adniec/pdoc3-mdnotes/',
        stack: [
          { text: 'Pdoc', image: 'pdoc', href: 'https://pdoc3.github.io/pdoc/' },
          { text: 'Python', image: 'python', href: 'https://www.python.org/' },
        ],
      },
      {
        title: 'MemorizeIT',
        text: 'OpenGL game made to train memory and focus among children. The target is to count correctly figures of each kind separately in periods of time with customizable speed. Simple and useful project idea was born during computer graphics classes on the university - my very first project uploaded to GitHub.',
        image: 'memorizeit',
        docs: 'https://adniec.github.io/memorizeit/',
        github: 'https://github.com/adniec/memorizeit',
        stack: [
          { text: 'OpenGL', image: 'opengl', href: 'https://www.opengl.org/' },
          { text: 'Pygame', image: 'pygame', href: 'https://www.pygame.org/' },
          { text: 'Python', image: 'python', href: 'https://www.python.org/' },
        ],
      },
      {
        title: 'bookmeister',
        text: 'Module responsible for communication with database hosted on restdb.io. Main goal was to develop simple and intuitive GUI for bookstore employees. It has high test coverage and proper documentation for both users and developers. Application created as a part of university project - bookstore.',
        image: 'bookmeister',
        docs: 'https://adniec.github.io/bookmeister/',
        github: 'https://github.com/adniec/bookmeister',
        stack: [
          { text: 'restdb.io', image: 'restdb', href: 'https://restdb.io/' },
          { text: 'Python', image: 'python', href: 'https://www.python.org/' },
        ],
      },
      {
        title: 'Pomodoro',
        text: 'I\'m not sure if I can even call it project, but I find intersting how quickly and with only few lines of code fully working Pomodoro timer can be developed in Haskell. I was extreamly inspired to create something on my own in this impressive language after some lectures about functional programming.',
        image: 'pomodoro',
        docs: '',
        github: 'https://github.com/adniec/Pomodoro',
        stack: [
          { text: 'Haskell', image: 'haskell', href: 'https://www.haskell.org/' },
        ],
      },
    ],
  }),
}
</script>
