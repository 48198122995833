<template>
  <v-app>
    <v-app-bar app color="background" elevation="0">
      <v-app-bar-nav-icon v-if="$vuetify.breakpoint.smAndDown" aria-label="Menu" role="img" aria-hidden="false"
        @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" class="v-btn-no-shadow" @click="$vuetify.theme.dark = !$vuetify.theme.dark"
            icon>
            <v-icon aria-label="Change theme" role="img" aria-hidden="false">{{
              mdiThemeLightDark
            }}</v-icon></v-btn>
        </template>
        <span>Change a theme's colors</span>
      </v-tooltip>

      <v-spacer></v-spacer>

      <v-hover v-slot="{ hover }" v-for="item in items" :key="item.text">
        <v-btn v-if="!$vuetify.breakpoint.smAndDown" @click="goToItem(item.component)"
          class="accent--text text--lighten-1 v-btn-no-shadow" :class="hover ? 'underline' : ''"
          style="font-size: 1em; font-weight: 700;" text>
          {{ item.text }}
        </v-btn>
      </v-hover>
    </v-app-bar>

    <v-navigation-drawer v-if="$vuetify.breakpoint.smAndDown" v-model="drawer" temporary app>
      <v-list nav dense>
        <v-list-item v-for="item in items" :key="item.text" @click="drawer = false; goToItem(item.component);">
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <div v-if="!$vuetify.breakpoint.smAndDown">
        <v-lazy v-for="item in items" :key="item.text" :id="item.component" v-model="item.isActive"
          :options="{ threshold: .9 }" :min-height="item.component === 'contact' ? '300' : '50vh'">
          <v-componenet :is="item.component" />
        </v-lazy>
      </div>

      <div v-if="$vuetify.breakpoint.smAndDown">
        <v-componenet :id="items[0].component" :is="items[0].component" />
        <v-lazy v-model="isActive" :options="{ threshold: .5 }" min-height="100vh">
          <div>
          <v-componenet :id="items[1].component" :is="items[1].component" />
          <v-componenet :id="items[2].component" :is="items[2].component" />
          <v-componenet :id="items[3].component" :is="items[3].component" />
        </div>

        </v-lazy>
      </div>

      <bottom-nav />
    </v-main>
  </v-app>
</template>

<script>
import Intro from '@/components/Intro';
import Certs from '@/components/Certs';
import BottomNav from '@/components/BottomNav';
import Contact from '@/components/Contact';
import Projects from '@/components/Projects';
import { mdiAccount, mdiThemeLightDark } from '@mdi/js'

export default {
  name: 'App',
  components: {
    Certs,
    BottomNav,
    Contact,
    Intro,
    Projects,
  },
  metaInfo() {
    return {
      title: "Adrian Nieć - portfolio",
      meta: [
        { name: 'description', content: 'This website is about Adrian Nieć and his experience in subject of computer science. Projects and certifications presented here show understanding of topics such as web development, Linux adminstration or a DevOps approach. Content of this portfolio is an invitation for a future employer to reach me and start cooperation.', },
        { property: 'og:title', content: "Adrian Nieć - portfolio" },
        { property: 'og:site_name', content: 'Adrian Nieć - portfolio' },
        { property: 'og:description', content: 'This website is about Adrian Nieć and his experience in subject of computer science. Projects and certifications presented here show understanding of topics such as web development, Linux adminstration or a DevOps approach. Content of this portfolio is an invitation for a future employer to reach me and start cooperation.' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' }
      ]
    }
  },
  data: () => ({
    isActive: true,
    mdiAccount,
    mdiThemeLightDark,
    drawer: false,
    items: [{
      text: 'Introduction',
      component: 'intro',
      isActive: true,
    },
    {
      text: 'Projects',
      component: 'projects',
      isActive: false,
    },
    {
      text: 'Certifications',
      component: 'certs',
      isActive: false,
    },
    {
      text: 'Contact',
      component: 'contact',
      isActive: false,
    },
    ],
  }),
  methods: {
    goToItem(destination) {
      for (const item of this.items) {
        item.isActive = true
        if (item.component === destination) {
          this.$nextTick(() => {
            this.$vuetify.goTo('#' + item.component)
          })
          break
        }
      }
    },
  },
};
</script>

<style>
@import '@/assets/styles/main.css';
</style>
