<template>
  <v-sheet outlined class="highlight" rounded>
    <v-hover v-slot="{ hover }">
      <v-card class="background" min-width="300px" width="29vw" height="100%" elevation="10" outlined>

        <v-img max-height="250px" :aspect-ratio="16 / 9" :src="require('@/assets/imgs/projects/' + image + '.webp')"
          :lazy-src="require('@/assets/imgs/projects/' + image + '_blur.webp')" :alt="title + ' project image'" contain>
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="grey lighten-1" aria-label="Loading icon"></v-progress-circular>
            </v-row>
          </template>

          <v-expand-transition>
            <v-card v-if="hover"
              class="d-flex transition-fast-in-fast-out background v-card--reveal-project white--text"
              style="height: 100%;">
              <v-card-text :class="$vuetify.breakpoint.lgAndDown ? '' : 'ma-16'"
                v-animate-css="getAnimation('fadeInUp', 100, 700)"
                :style="'font-size: ' + ($vuetify.breakpoint.smAndDown ? '0.6em' : '1em') + ';'">
                {{ text }}
              </v-card-text>
            </v-card>
          </v-expand-transition>
        </v-img>

        <v-card-title class="ma-1">
          <v-row>
            {{ title }}
            <v-spacer></v-spacer>
            <technologies v-if="$vuetify.breakpoint.xl" class="ml-auto" :stack="stack" size="20" />
          </v-row>
        </v-card-title>

        <v-card-actions>
          <v-row class="ml-auto mr-5">
            <v-spacer></v-spacer>
            <v-btn v-if="view" class="mb-5 accent--text text--lighten-1" :href="view" target="_blank" text>
              Explore
            </v-btn>
            <v-btn v-if="github" class="mb-5 accent--text text--lighten-1" :href="github" target="_blank" text>
              Source Code
            </v-btn>
            <v-btn v-if="docs" class="mb-5 accent--text  text--lighten-1" :href="docs" target="_blank" outlined>
              Docs
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-hover>
  </v-sheet>
</template>

<script>
import Technologies from '@/components/Technologies';
import { animateMixin } from "@/mixins/animate"

export default {
  name: 'Project',
  components: {
    Technologies,
  },
  props: ["title", "text", "image", "view", "docs", "github", "stack"],
  mixins: [animateMixin],
}
</script>
