<template>
  <v-card flat class="ma-8 border" style="border-radius: 30px;" height="100" color="transparent">

    <v-row>
      <v-col cols="auto">
        <v-card-title class="mx-16 accent-darken--text skills-header">Experience</v-card-title>
      </v-col>
    </v-row>

    <v-row class="mx-10">
      <technologies :stack="skills" size="40" style="position: relative; bottom: 55px;" />
    </v-row>
  </v-card>
</template>

<script>
import Technologies from '@/components/Technologies';

export default {
  name: 'Skills',
  components: {
    Technologies,
  },
  data: () => ({
    skills: [
      { text: 'Python', image: 'python', href: 'https://www.python.org/' },
      { text: 'JavaScript', image: 'js', href: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript' },
      { text: 'Bash', image: 'bash', href: 'https://www.gnu.org/software/bash/' },
      { text: 'Linux', image: 'linux', href: 'https://www.linux.org/' },
      { text: 'GitHub', image: 'github', href: 'https://github.com/' },
      { text: 'GitLab', image: 'gitlab', href: 'https://gitlab.com/' },
      { text: 'Docker', image: 'docker', href: 'https://www.docker.com/' },
      { text: 'AWS | Azure | GCP', image: 'cloud', href: 'https://cloud.google.com/free/docs/aws-azure-gcp-service-comparison' },
      { text: 'Visual Studio Code', image: 'vscode', href: 'https://code.visualstudio.com/' },
      { text: 'PyCharm', image: 'pycharm', href: 'https://www.jetbrains.com/pycharm/' },
      { text: 'Terraform', image: 'terraform', href: 'https://www.terraform.io/' },
      { text: 'DevOps Methodology', image: 'devops', href: 'https://medium.com/@techeducative/what-is-devops-e0a77188c5e3' },
      { text: 'Cloudflare', image: 'cloudflare', href: 'https://www.cloudflare.com/' },
    ],
  }),
}
</script>
